@import "./scss/global/shared.scss";
/* HOMEPAGE TOP
-------------------------------------------------------------- */
.homepage-top {
    margin-bottom: 0px;
    width: 100%;
    padding-bottom: 0;

    &__slider {
        position: relative;
        height: 100%;
        width: 100vw;
        margin-left: calc((-100vw + 100%) / 2);
        margin-right: calc((-100vw + 100%) / 2);
        padding: 0 0 24px;

        @media #{$media-sm} {
            margin-bottom: 24px;
            padding: 0;
        }

        //@media #{$media-xs} {
        @media (max-width: 1024px) {
            margin-bottom: 24px;
            padding: 0;
        }

        .slider-main {
            height: 100%;
        }
    }

    &__chat {
        width: 100vw;
        margin-left: calc((-100vw + 100%) / 2);
        margin-right: calc((-100vw + 100%) / 2);
        padding: 20px 0;
        background-image: $background-pattern;

        @media #{$media-xs} {
            padding-bottom: 0;
            width: 90vw;
            margin-left: calc((-90vw + 100%) / 2);
            margin-right: calc((-90vw + 100%) / 2);
        }

        .container {
            padding: 0;
            width: 100%;
        }
    }

    .product-shop__wishlist {
        display: none !important;
    }
    .add-to-cart-button-wrapper {
        display: inline-block;
        width: 10% !important;
    }

    // .chat-box {
    //     height: 100%;

    //     &__title {
    //         &::selection,
    //         img::selection,
    //         .title::selection {
    //             background: transparent;
    //         }
    //     }
    // }
}

/* HOMEPAGE PRODUCT LISTS
-------------------------------------------------------------- */
.homepage-products {
    margin: 60px 0;
    margin-top: 60px !important;

    @media #{$media-xs} {
        margin-top: 10px !important;
    }

    &:last-of-type {
        border-bottom: none;
        margin-bottom: 0;

        @media #{$media-xs} {
            margin-top: 60px !important;
        }
    }

    .page-subtitle {
        position: relative;

        @media #{$media-xs} {
            text-align: center;
        }
    }

    > .row {
        @media #{$media-sm} {
            max-width: 626px;
            margin-left: auto;
            margin-right: auto;
        }

        .product-list__item {
            @media #{$media-sm} {
                padding-left: 25px;
                padding-right: 25px;
            }
        }
    }
    .product-shop__wishlist {
        display: none !important;
    }
}

/* CONTENT LINKS
-------------------------------------------------------------- */
.homepage-content {
    margin-bottom: 15px;

    .article-list {
        margin-bottom: 40px;

        &:not(.article-list--img) {
            @media #{$media-md-up} {
                &:nth-of-type(2n + 1) {
                    clear: both;
                }
            }
        }
    }

    .article-list--img {
        @media #{$media-lg} {
            &:nth-of-type(4n + 1) {
                clear: both;
            }
        }

        @media #{$media-md} {
            &:nth-of-type(3n + 1) {
                clear: both;
            }
        }

        @media #{$media-sm} {
            &:nth-of-type(2n + 1) {
                clear: both;
            }
        }
    }
}
.content-main {
    .homepage{
        .container {
            @media #{$media-xl} {
                width: 1622px !important;
            }
        }

        .container {
            @media #{$media-xxl} {
                width: 1920px !important;

                .container-lg {
                    width: 1730px;
                    max-width: 1730px !important;
                    margin: 0 auto;
                }
                .container-default {
                    width: 1622px;
                    max-width: 1622px !important;
                    margin: 0 auto;
                }
            }
        }
    }
}

.cms-strona-glowna {
    .content-main{
        .container {
            @media #{$media-xl} {
                width: 1622px !important;
            }
        }

        .container {
            @media #{$media-xxl} {
                width: 1920px !important;

                .container-lg {
                    width: 1730px;
                    max-width: 1730px !important;
                    margin: 0 auto;
                }
                .container-default {
                    width: 1622px;
                    max-width: 1622px !important;
                    margin: 0 auto;
                }
            }
        }
    }
}

.cms-index-index {
    .content-main {
        padding-bottom: 20px;

        @media #{$media-xs} {
            padding-bottom: 10px;
        }
    }

    .footer {
        padding-top: 0;

        &__mega-links-wrapper {
            background: #f5f5f5;
            padding-top: 30px;

            @media #{$media-xs} {
                padding-top: 0;
            }
        }
    }
}

.homepage {
    .see-more {
        display: flex;
        justify-content: center;

        @media #{$media-xs} {
            margin-top: 16px;
        }

        &:hover {
            a {
                color: $dark-green;
            }

            svg path{
                fill: $dark-green;
            }
        }

        .link-arrow__icon {
            vertical-align: text-bottom;
            height: 19px;
        }

        a {
            color: $sherpa-blue;
            font-size: 16px;
            font-weight: 600;
            line-height: 28px;

            @media #{$media-xs} {
                font-size: 14px;
                line-height: 22px;
            }
        }
    }

    .box-article {
        .product-collection__btn {
            display: none;
        }
        .slider-main__dots {
            display: block;
        }

        .title {
            font-size: 32px;
            font-weight: 600;
            color: $color-text-headings;
            text-align: center;

            @media #{$media-xs} {
                font-size: 16px;
                text-align: left;
            }
        }

        .article-section {
            max-width: 100%;

            &__slider {
                padding: 0;
            }

            .c-tabs__content  {
                background-color: transparent;
                padding-top: 0;

                @media #{$media-xs} {
                    padding-bottom: 0;
                }

                &.is-active {
                    .product-collection__btn {
                        display: none;
                    }
                    .slider-main__dots {
                        display: block;
                        width: 100%;
                        position: unset;
                        transform: none;
                        margin: 32px 0;

                        @media #{$media-xs} {
                            margin: 16px 0;
                        }
                    }

                    .glider-track {
                        gap: 16px;
                    }

                    .article-teaser {
                        max-width: 100%;
                        color: #fff;
                        font-weight: 400;
                        margin: 0;
                        border: none;
                        height: 100% !important;
                        box-shadow: none;
                        padding: 0;

                        @media #{$media-lg} {
                            flex: 1;
                            margin: 0 40px;
                            padding-bottom: 3px;
                        }

                        @media #{$media-md} {
                            margin: 0 10px;
                        }

                        @media #{$media-sm} {
                            margin: 0 10px;
                        }

                        &__sponsored {
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 28px;
                            height: 1lh;

                            @media #{$media-xs} {
                                font-size: 12px;
                                line-height: 24px;
                                margin-bottom: 0px;
                            }
                        }

                        &__title {
                            color: #fff;
                            font-size: 34px;
                            font-weight: 400;
                            line-height: 48px;
                            height: 2lh;

                            @media #{$media-xs} {
                                font-size: 16px;
                                font-weight: 600;
                                line-height: 24px;
                            }
                        }

                        &__text {
                            font-size: 20px;
                            font-weight: 400;
                            line-height: 34px;
                            height: 4lh;

                            @media #{$media-xs} {
                                font-size: 16px;
                                line-height: 24px;
                            }
                        }

                        &__img {
                            width: 100%;
                            height: 408px;
                            margin-bottom: 0;
                            display: block;

                            @media #{$media-xl} {
                                max-width: 424px;
                            }

                            @media #{$media-lg} {
                                min-width: 320px;
                            }

                            @media #{$media-md} {
                                max-width: 330px;
                            }

                            @media #{$media-sm} {
                                margin-right: 20px;
                                max-width: 300px;
                            }

                            @media #{$media-xs} {
                                height: 216px;
                                margin: 0 auto;
                            }

                            @media #{$media-xs-l} {
                                height: 175px;
                            }
                        }

                        &__wrapper {
                            display: flex;
                            gap: 10px;
                            max-width: 1380px;

                            @media #{$media-xs} {
                                flex-flow: row wrap;
                                width: 100%;

                                gap: 16px;
                            }
                        }

                        &__more-btn {
                            flex-grow: 1;
                            display: flex;
                            align-items: flex-end;
                        }

                        &__more {
                            border-color: #fff;
                            background-color: #fff;
                            color: #2F3146;
                            min-width: 100%;
                            font-weight: $font-weight-bold;
                        }


                        &__desc {
                            background-color: #007F32;
                            border-radius: 12px;
                            padding: 24px;
                            display: flex;
                            flex-flow: column;
                            width: 100%;
                            min-height: 272px;

                            @media #{$media-xs} {
                                min-height: 312px;
                            }
                        }
                    }
                }
            }
        }
    }
    .c-products-slider {
        .prod-box {
            max-width: 336px;
            height: 556px;
            padding: 24px;
            border-radius: 16px;
            border: 1px solid #cee7d8;
            box-shadow: 0 4px 8px rgba(47,49,70,.1);
            margin: 0;

            @media #{$media-sm} {
                height: auto;
            }

            @media #{$media-xs} {
                padding: 7px 5px;
                max-width: 152px;
                height: 397px;
            }

            &__main-link {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                margin: 0 25px;
                height: 80%;
            }
            &--product-of-the-week {
                border: 1px solid #e83968;
                border-radius: 10px;
            }

            &__img {
                position: relative;
                display: flex;
                min-height: 0;
                height: 246px;
                width: auto;
                border-bottom: none;
                padding-bottom: 0;
                padding-top: 0;
                @media #{$media-xs} {
                    height: 136px;
                    padding-top: 0;
                }

                strong {
                    position: absolute;
                    bottom: 10px;
                }

                .prod-box__labels {
                    left: -6px;
                    top: 0;

                    @media #{$media-xs} {
                        left: -4px;
                        top: -3px;
                        max-width: 74px;
                        font-size: 10px;

                        a {
                            height: 24px;
                            max-width: 95px;
                            padding: 0;
                        }
                    }
                }

                img {
                    max-width: 216px !important;
                    max-height: 216px;

                    @media #{$media-xs} {
                        max-width: 136px !important;
                        max-height: 136px;
                    }
                }
            }
            &__name {
                font-size: 16px;
                font-weight: 600;
                line-height: 28px;
                padding-bottom: 10px;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                height: 3lh;

                @media #{$media-xs} {
                    margin-bottom: 4px;
                    font-size: 14px;
                    line-height: 21.7px;
                    height: 3lh;
                    padding: 0 8px;
                }
            }
            &__type {
                display: block;
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                overflow: hidden;
                height: 2lh;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                display: -webkit-box;

                @media #{$media-xs} {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 18px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    text-transform: lowercase;
                    padding: 0 8px;
                }
            }

            &__price {
                font-size: 20px;
                font-weight: 600;
                line-height: 34px;
                margin-top: 25px;

                @media #{$media-to-sm} {
                    font-size: 16px;
                    margin-top: 0;
                    padding: 0 8px;
                }
            }
            &__btns {
                position: relative;
                display: flex;
                flex-direction: row;
                height: 48px;
                min-height: 32px;
                margin: 8px 0;
                padding: 0;
                @media #{$media-xs} {
                    height: auto;
                    margin: 0;
                    padding: 0 8px;
                }

                .prod-box__btn {
                    @media #{$media-to-sm} {
                        max-width: 130px;
                        min-height: 40px;
                        height: 40px;
                        font-size: 10px;
                        line-height: 12px;
                        padding: 5px;
                        width: 100%;

                        &::after {
                            width: 18px;
                            mask-size: 18px;
                            top: 9px;
                            right: 5px;
                        }
                    }
                }

                .product-shop__link--wishlist {
                    top: 0;
                    right: 2px;
                    bottom: 96px;
                    align-items: center;
                    background: transparent;
                    border-radius: 50%;
                    display: flex;
                    min-height: 40px;
                    height: 40px;
                    justify-content: center;
                    width: 40px;
                    @media #{$media-xs} {
                        background: #fff;
                    }
                    &.wishlist-added {
                        .product-options__icon {
                            background: none;
                            path {
                                fill: $sherpa-blue;
                                stroke: none;
                            }
                        }
                    }

                    &:hover {
                        .product-options__icon {
                            background: none;
                            path{
                                fill: $sherpa-blue;
                                stroke: none;
                            }
                        }
                    }

                    &:focus {
                        .product-options__icon {
                            background: none;
                            path{
                                fill: $sherpa-blue;
                                stroke: none;
                            }
                        }
                    }

                    svg path{
                        fill: #2F3146;
                    }

                    @media #{$media-to-sm} {
                        right: 5px;
                        top: 5px;
                    }

                    @media #{$media-xs} {
                        right: -5px;
                        top: -282px;
                    }
                }

                form {
                    flex-basis: 75%;
                    padding-left: 0;
                    padding-right: 0;
                    padding-top: 0;

                    @media #{$media-xs} {
                        padding-top: 0;
                        flex-basis: 100%;
                    }
                }
            }

            .tooltip-max-price-info {
                padding: 0;
                margin: 0;

                @media #{$media-to-sm} {
                    padding: 0 0 16px 0;
                    p {
                        margin: 8px 0;
                        font-size: 10px;
                        font-weight: 400;
                        line-height: 15px;
                        padding: 0 5px;
                    }
                }
            }
        }

        .article-teaser {
            max-width: 424px;
            height: 425px !important;
            border-radius: 10px;
            box-shadow: 0 4px 8px rgba(47,49,70,.1);
            padding: 8px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            @media #{$media-xs} {
                padding: 0;
            }

            .article-teaser__img-wrapper{
                position: relative;

                .article-teaser__span-category{
                    position: absolute;
                    top: 80%;
                    right: 10px;
                    width: fit-content;
                    height: 38px;
                    overflow: hidden;

                    @media #{$media-xs} {
                        top: 64%;
                    }
                }
            }

            &__img {
                position: relative;
                display: block;
                max-width: 100%;
                min-width: auto;
                height: 229px;
                min-height: 230px;
                width: 408px;
                margin: 0 auto;
                border-bottom: none;
                padding-bottom: 0;

                @media #{$media-xs} {
                    height: 159px;
                    min-height: 159px;
                    min-width: auto;
                    margin-bottom: 14px;
                }

                img {
                    width: 408px !important;
                    max-height: 100%;

                    @media #{$media-xs} {
                        width: 100% !important;
                    }
                }
            }

            &__category {
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
                width: fit-content;
                max-width: 350px;
                padding: 8px 14px;
                height: 38px;
                background-color: #FFFFFF;
                border-radius: 50px;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                display: -webkit-box;
                text-align: center;

                &::after {
                    content: none;
                }
            }

            &__desc {
                display: flex;
                flex-direction: column;
                padding: 0 8px;

                .date {
                    margin-top: 16px;
                    font-size: 12px;
                    line-height: 18px;

                    @media #{$media-xs} {
                        margin-top: 0;
                    }
                }
                .title {
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 30px;
                    height: 56px;
                    margin-bottom: 8px;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }

                .text {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 22px;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }

                @media #{$media-xs} {
                    .date {
                        font-size: 10px;
                        line-height: 15px;
                    }
                    .title {
                        font-size: 14px;
                        line-height: 22px;
                        height: 44px;
                    }

                    .text {
                        font-size: 12px;
                        line-height: 18px;
                        height: 54px;
                    }
                    p{
                        margin: 0 0 8px;
                    }
                }

            }
            @media  #{$media-xs} {
                height: 324px !important;
            }
        }

    }

    .c-tabs {
        @media #{$media-xs} {
            padding-top: 0;
            margin-top: 0;
        }

        &:after {
            @media #{$media-to-sm} {
                background: none;
            }
        }

        .header-article-list-mobile{
            display: none;
            max-width: 1616px;
            width: 100%;
            margin: auto;
            padding: 32px 12px 12px;

            @media #{$media-xs} {
                display: block;
                padding: 16px 12px 0;

                h2{
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                }
            }
        }

        .header-article-list-desktop {
            width: fit-content;
            margin: 0 auto;

            @media #{$media-xs} {
                padding: 0 12px 0;
                width: 100%;
                margin: 0;
            }

            h2 {
                color: $color-text-headings;
                font-size: 32px;
                line-height: 48px;
                @media #{$media-xs} {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                }
            }
        }

        &__header {
            border: 1px solid #007F32;
            border-radius: 30px;
            background-color: $white;
            color: #2F3146;
            list-style: none;
            margin: 0 16px 0 0;

            &:hover {
                background-color: $dark-green;
                border-color: $dark-green;

                p {
                    color: $white;
                }
            }

            &.is-active {
                border-radius: 30px;
                background-color: #007F32;

                p {
                    color: $white;
                }
            }

            p {
                font-size: 14px;
                font-weight: 600;
                line-height: 22px;
                text-align: center;
            }
        }

        &__content {
            &.is-active {
                padding: 32px 106px 0 106px;

                @media #{$media-to-md} {
                    padding: 32px 40px;
                }

                @media #{$media-xs} {
                    padding: 24px 0 0;
                }
            }
        }

        &__list {
            max-width: 1616px;
            width: 100%;
            margin: auto;
            padding: 32px 110px 0 110px;

            @media only screen and (max-width: 1300px) {
                padding-left: 0;
                padding-top: 10px;
            }

            @media #{$media-xs} {
                padding: 16px 12px 0 0;
            }
        }

        &__wrapper {
            width: 100%;
            margin: auto;

            .c-tabs__content {
                max-width: 1730px;
                background-color: $gray-light;
                margin: auto;


                .glider {
                    max-width: 1512px;

                    .glider-track {
                        justify-content: space-evenly;
                        @media only screen and (max-width: 1300px) {
                            min-width: 142px;
                            gap: 8px;
                        }

                        .glider-slide {
                            max-width: 336px;
                            width: 100%;
                        }
                    }
                }


                .slider-main__dots {
                    height: 40px;
                    width: 100%;
                    bottom: unset;
                    align-items: center;

                    @media #{$media-to-md} {
                        display: inline-flex;
                    }
                }

                .product-collection__btn {
                    @media #{$media-to-md} {
                        display: none;
                    }
                    &::after {
                        width: 56px;
                        height: 60px;
                        border-radius: 50px;
                        margin-top: -30px;
                        margin-left: -29px;
                    }
                    &--left {
                        left: 18px;
                    }

                    &--right {
                        right: 15px;
                    }

                    .icon {
                        width: 24px;
                        height: 24px;
                    }
                    &:hover {
                        &:after {
                            @include n-theme() {
                                border-color:  $green-hover;
                            }
                        }

                        svg {
                            path {
                                stroke: $stroke-hover;
                            }
                        }
                    }

                    &.disabled:hover {
                        &:after {
                            border-color: $sherpa-blue-light;
                        }
                    }
                }
            }
        }
    }

    .box-may-interested-you {
        padding: 24px;
        background: $gray-light;
        margin-top: 24px;
        margin-bottom: 24px;
        margin-left: calc(-50vw + 50%);
        margin-right: calc(-50vw + 50%);

        @media #{$media-xs} {
            border-left: none;
            border-right: none;
            border-radius: unset;
            padding: 12px;
            margin: 12px -15px;
            padding-right: 0;
        }

        .title {
            color: $color-text-headings;
            font-size: 32px;
            font-weight: 600;
            line-height: 48px;
            text-align: center;
            @media #{$media-xs} {
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                text-align: left;
            }
        }

        .see-more {
            margin-top: 32px;

            @media #{$media-xs} {
                margin-top: 16px;
            }
        }
        .may-interested-you-tabs {
            .c-tabs__list {
                padding: 32px 90px 0;

                @media #{$media-xs} {
                    padding: 24px 0 0 0;
                }
            }
        }
    }

    .category_article_box {
        padding: 24px;
        background: $white;
        margin-left: calc(-50vw + 50%);
        margin-right: calc(-50vw + 50%);

        @media #{$media-xs} {
            border-left: none;
            border-right: none;
            border-radius: unset;
            padding: 20px 0 20px 20px;
            margin: 0 -15px;
        }

        .c-products-slider {
            background-color: $white;

            .glider-track {
                justify-content: space-between;

                .article-teaser {
                    width: 424px !important;
                }
            }
        }
    }
}

#section-chat {
    padding: 24px 0;
    margin: 24px calc(-50vw + 50%);
    background-image: $background-pattern;

    @media #{$media-xs} {
        padding: 12px 0;
        margin: 12px calc(-50vw + 50%);
    }
}

.category_article_box {
    .c-products-slider {
        .article-teaser {
            max-width: 424px !important;
        }
    }
}

.box-may-interested-you {
    .slider {
        &--row {
            overflow-y: hidden;
            max-height: 570px;

            @media #{$media-xs} {
                margin-right: -20px;
            }
        }
    }
}

.box-article {
    h2 {
        line-height: 48px;
        padding: 24px 32px 0;

        @media #{$media-xs} {
            padding: 12px 4px 0;
            line-height: 24px;
        }
    }

    .article-section {
        padding: 32px 0 24px;

        @media #{$media-xs} {
            padding: 12px 0;
        }
    }
}

h2 {
    margin-bottom: 0px;
}

h2.banner-widget__title,
h2.popular-categories__title {
    margin-bottom: 0;
    line-height: 48px;

    @media #{$media-xs} {
        margin-top: 0;
        line-height: 24px;
    }
}
.popular-categories {
    .popular-categories__wrapper {
        margin-top: 32px;

        @media #{$media-xs} {
            margin-top: 16px;
        }
    }
}

.banner-widget {
    .banner-widget__wrapper {
        margin-top: 32px;

        @media #{$media-xs} {
            margin-top: 16px;
        }
    }
}

.megamenu-sub {
    .megamenu-sub__content {
        .prod-box{
            form {
                button {
                    padding: 12px 10px 10px;
                    min-height: 40px;
                    svg{
                        path {
                            fill: #525362;
                        }
                    }
                }
            }
        }
    }
}



